import React from 'react';
import TrainingBanner from "../components/teacherTraining/TrainingBanner";
import TrainingIntro from "../components/teacherTraining/TrainingIntro";
import TrainingModules from "../components/teacherTraining/TrainingModules";
import TrainingMotivation from "../components/teacherTraining/TrainingMotivation";
import TrainingLeaders from "../components/teacherTraining/TrainingLeaders";
import TrainingPricing from "../components/teacherTraining/TrainingPricing";
import {Link} from "react-router-dom";

const TeacherTraining = () => {
    return (<>
            <Link to='/' className='back-to-main back-to-main--white'>
                POWRÓT DO STRONY GŁÓWNEJ
            </Link>
            <div className='training'>
                <TrainingBanner/>
                <div className='training_container'>
                    <TrainingIntro/>
                    <TrainingModules/>
                    <TrainingMotivation/>
                    <TrainingLeaders/>
                    <TrainingPricing/>
                </div>
            </div>
        </>
    );
};

export default TeacherTraining;