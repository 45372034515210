import SuccessBanner from "../components/success/SuccessBanner";
import SuccessIntro from "../components/success/SuccessIntro";
import SuccessGoals from "../components/success/SuccessGoals";
import SuccessCreators from "../components/success/SuccessCreators";
import SuccessLogotypes from "../components/success/SuccessLogotypes";
import SuccessRelations from "../components/success/SuccessRelations";
import {Link} from "react-router-dom";
import React from "react";

const Success = () => {
    return (
        <>
            <Link to='/' className='back-to-main back-to-main--white'>
                POWRÓT DO STRONY GŁÓWNEJ
            </Link>
            <div className='success'>
                <SuccessBanner/>
                <div className='success_container'>
                    <SuccessIntro/>
                    <SuccessGoals/>
                    <SuccessCreators/>
                    <SuccessLogotypes/>
                    <SuccessRelations/>
                </div>
            </div>
        </>

    );
}

export default Success;