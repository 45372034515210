import {IMAGES_1402, IMAGES_3101} from './successImages'
import Gallery from "react-grid-gallery";

const SuccessRelations = () => {
    const sections = [{
        images: IMAGES_3101,
        text: 'W dniu 31 stycznia rozpoczęliśmy swoją podróż w ramach projektu "Sukcesu nie mierzy się oceną" od Szkoły Podstawowej nr 3 w Lęborku, aby zakończyć ją w Zespole Szkół Gospodarki Żywnościowej i Agrobiznesu w Lęborku. 💚<br/><br/>Uczniowie klas I-V poznawali tajniki niezwykłej metody Brainy Abacus, która rozwija funkcje poznawcze i wykonawcze mózgu, dając dziecku w efekcie umiejętność bardzo szybkiego liczenia i bardzo dobrą znajomość matematyki. 💚<br/><br/>Starsi uczniowie z klas VI – VIII oraz szkoły ponadpodstawowej poznawali natomiast tajniki działania elektrowni jądrowych oraz węglowych podczas zajęć z fizyki. Uczniowie dowiedzieli się jak te dwie formy produkcji energii wpływają na nasze życie codzienne i środowisko, jak energia jest przekształcana z paliwa w energię elektryczną oraz zrozumieli różnice pomiędzy tymi technologiami. 💚<br/><br/>Dla Nas w tym projekcie niezwykle ważne są relacje, kontakt z młodymi ludźmi, ukazanie im potęgi nauki. Wiemy, że nowe technologie rozwijają się w niezwykłym tempie, a sztuczna inteligencja wiele kwestii ułatwia. Jednak wciąż uważamy, że dzieci i młodzież potrzebują na swoich naukowych ścieżkach dobrych mentorów. Nic bowiem Naszym zdaniem nie zastąpi relacji, rozgrzewających się miłością do nauki serc, wsparcia, które dziecko i nastolatek otrzyma od mądrego przewodnika. 💚<br/><br/>I właśnie te i wiele innych powodów sprawiają, że będziemy podróżować, poszukiwać i otaczać wsparciem młodych, zdolnych ludzi...zdecydowanie w relacjach offline. 💚',
        title: "Zajęcia w Lęborku",
        date: "31.01.2025"
    },
    {
        images: IMAGES_1402,
        text: 'W walentynkowy poranek odwiedziliśmy I Liceum Ogólnokształcące im. Króla Jana III Sobieskiego w Wejherowie i oczywiście poprowadziliśmy zajęcia w ramach Naszego projektu "Sukcesu nie mierzy się oceną". 💚<br/><br/>Prawie 40 młodych ludzi wsłuchało się w opowieści o prądzie, elektrowniach jądrowych oraz ich znaczeniu w rozwijającym się technologicznie świecie. 💚<br/><br/>Dla Nas każde spotkanie jest niezwykłe, gdyż dzięki temu dowiadujemy się jak wiele jest dzieci i młodzieży zainteresowanych naukami ścisłymi. Pragną oni kontynuować edukację na uczelniach takich jak Politechnika, studiować fizykę i marzą wielkie marzenia. 💚<br/><br/>Nasz projekt jest niezwykłą przestrzenią, którą aranżujemy właśnie po to, aby pokazać młodym ludziom, że nauka stawia dziś wiele pytań, a świat stoi przed wielkimi wyzwaniami, a oni, jeśli uwierzą w swoje niezwykłe talenty, mogą znaleźć odpowiedzi, rozwiązania i zmieniać świat na lepsze. 💚<br/><br/>Cudownie jest Was spotykać, móc z Wami porozmawiać i towarzyszyć Wam choć przez chwilę. 💚',
        title: "Zajęcia w Wejherowie",
        date: "14.02.2025"
    }]
    return (
        <div className='success_relations'>
            <h3>Relacje z wydarzeń</h3>
            {sections.map(section => (
                <section>
                    <Gallery images={section.images} margin={0} enableImageSelection={false}/>
                    <div>
                        <h6>{section.date}</h6>
                        <h4>{section.title}</h4>
                        <p dangerouslySetInnerHTML={{__html: section.text}}/>
                    </div>
                </section>
            ))}
        </div>
    );
};

export default SuccessRelations;