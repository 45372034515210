import IMAGES_3101_FULL_1 from '../../assets/img/success/relations/3101/20250131_1.jpg'
import IMAGES_3101_FULL_2 from '../../assets/img/success/relations/3101/20250131_2.jpg'
import IMAGES_3101_FULL_3 from '../../assets/img/success/relations/3101/20250131_3.jpg'
import IMAGES_3101_FULL_4 from '../../assets/img/success/relations/3101/20250131_4.jpg'
import IMAGES_3101_FULL_5 from '../../assets/img/success/relations/3101/20250131_5.jpg'
import IMAGES_3101_FULL_6 from '../../assets/img/success/relations/3101/20250131_6.jpg'
import IMAGES_3101_FULL_7 from '../../assets/img/success/relations/3101/20250131_7.jpg'
import IMAGES_3101_FULL_8 from '../../assets/img/success/relations/3101/20250131_8.jpg'
import IMAGES_3101_FULL_9 from '../../assets/img/success/relations/3101/20250131_9.jpg'

import IMAGES_3101_ICON_1 from '../../assets/img/success/relations/3101/20250131_1_icon.jpg'
import IMAGES_3101_ICON_2 from '../../assets/img/success/relations/3101/20250131_2_icon.jpg'
import IMAGES_3101_ICON_3 from '../../assets/img/success/relations/3101/20250131_3_icon.jpg'
import IMAGES_3101_ICON_4 from '../../assets/img/success/relations/3101/20250131_4_icon.jpg'
import IMAGES_3101_ICON_5 from '../../assets/img/success/relations/3101/20250131_5_icon.jpg'
import IMAGES_3101_ICON_6 from '../../assets/img/success/relations/3101/20250131_6_icon.jpg'
import IMAGES_3101_ICON_7 from '../../assets/img/success/relations/3101/20250131_7_icon.jpg'
import IMAGES_3101_ICON_8 from '../../assets/img/success/relations/3101/20250131_8_icon.jpg'
import IMAGES_3101_ICON_9 from '../../assets/img/success/relations/3101/20250131_9_icon.jpg'

import IMAGES_1402_FULL_1 from '../../assets/img/success/relations/1402/20250214_1.jpeg'
import IMAGES_1402_FULL_2 from '../../assets/img/success/relations/1402/20250214_2.jpeg'
import IMAGES_1402_FULL_3 from '../../assets/img/success/relations/1402/20250214_3.jpeg'
import IMAGES_1402_FULL_4 from '../../assets/img/success/relations/1402/20250214_4.jpeg'
import IMAGES_1402_FULL_5 from '../../assets/img/success/relations/1402/20250214_5.jpeg'
import IMAGES_1402_FULL_6 from '../../assets/img/success/relations/1402/20250214_6.jpeg'

import IMAGES_1402_ICON_1 from '../../assets/img/success/relations/1402/20250214_1_icon.jpeg'
import IMAGES_1402_ICON_2 from '../../assets/img/success/relations/1402/20250214_2_icon.jpeg'
import IMAGES_1402_ICON_3 from '../../assets/img/success/relations/1402/20250214_3_icon.jpeg'
import IMAGES_1402_ICON_4 from '../../assets/img/success/relations/1402/20250214_4_icon.jpeg'
import IMAGES_1402_ICON_5 from '../../assets/img/success/relations/1402/20250214_5_icon.jpeg'
import IMAGES_1402_ICON_6 from '../../assets/img/success/relations/1402/20250214_6_icon.jpeg'


export const IMAGES_3101 = [
    {
        src: IMAGES_3101_FULL_1,
        thumbnail: IMAGES_3101_ICON_1,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_2,
        thumbnail: IMAGES_3101_ICON_2,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_3,
        thumbnail: IMAGES_3101_ICON_3,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_4,
        thumbnail: IMAGES_3101_ICON_4,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_5,
        thumbnail: IMAGES_3101_ICON_5,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_6,
        thumbnail: IMAGES_3101_ICON_6,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_7,
        thumbnail: IMAGES_3101_ICON_7,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_8,
        thumbnail: IMAGES_3101_ICON_8,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_3101_FULL_9,
        thumbnail: IMAGES_3101_ICON_9,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    },
];

export const IMAGES_1402 = [
    {
        src: IMAGES_1402_FULL_1,
        thumbnail: IMAGES_1402_ICON_1,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_1402_FULL_2,
        thumbnail: IMAGES_1402_ICON_2,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_1402_FULL_3,
        thumbnail: IMAGES_1402_ICON_3,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_1402_FULL_4,
        thumbnail: IMAGES_1402_ICON_4,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_1402_FULL_5,
        thumbnail: IMAGES_1402_ICON_5,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }, {
        src: IMAGES_1402_FULL_6,
        thumbnail: IMAGES_1402_ICON_6,
        thumbnailWidth: 175,
        thumbnailHeight: 175,
    }
]